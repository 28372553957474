<template>
  <v-card rounded="lg" min-height="70vh">
    <v-row class="mx-1" v-if="loading">
      <v-col class="d-flex child-flex" xs="12" sm="6" md="6" lg="6" xl="4" v-for="i in 6" :key="i">
        <v-skeleton-loader type="card, paragraph, divider, list-item, date-picker-days"/>
      </v-col>
    </v-row>
    <v-row class="mx-1">
      <v-dialog v-if="selectedImage" :value="!!selectedImage" scrollable :max-width="selectedImageWidth">
        <v-card>
          <v-toolbar dark color="light-blue lighten-1">
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="selectedImage = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-img :src="selectedImage" @click.stop="selectedImage = null" style="cursor: pointer" :max-width="selectedImageWidth"/>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col class="d-flex child-flex" xs="12" sm="6" md="6" lg="4" xl="4" v-for="(project) in projects" :key="project._id">
        <v-card>
          <v-img
              ref="projectImage"
              class="white--text align-start"
              gradient="to bottom left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
              height="200px"
              :src="project.image"
          >
            <v-app-bar color="transparent" flat>
              <v-spacer/>
              <v-btn icon color="white" @click="selectedImage = project.image">
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>
              <v-btn icon color="white" :href="project.url" target="_blank">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-app-bar>
            <v-card-title v-text="project.name" class="pt-16 mt-8"/>
          </v-img>

          <v-card-subtitle>
            {{ project.type }}
          </v-card-subtitle>

          <v-card-text class="text--primary">
            <div class="mt-2">© {{ project.customer }}</div>
            <div>© {{ project.company }}</div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            <v-chip-group column disabled="true">
              <v-chip v-for="(field, slug) in project.fields" :key="field._id" small>
                <v-icon left small>
                  {{ getIconName(slug) }}
                </v-icon>
                {{ field }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    selectedItem: {
      type: String,
      default: null
    }
  },
  data: () => ({
    selectedImage: null,
    selectedImageWidth: null,
    loading: true,
  }),
  watch: {
    selectedImage(newImage) {
      this.selectedImageWidth = null;
      if (newImage) {
        let image = new Image();
        image.onload = () => {
          this.selectedImageWidth = image.width > window.innerWidth ? '100%' : image.width;
        }
        image.src = newImage;
      }
    }
  },
  computed: {
    projects(){
      let projects = this.$store.getters['projects/projects'] || [];
      return projects.length
          && projects
              .filter(project => project.image)
              .filter(project => !this.selectedItem || Object.keys(project.fields).includes(this.selectedItem))
    },
    carousel(){
      return this.projects && this.projects.map(project => project.image);
    },
    modalHeight() {
      return window.innerHeight;
    },
    modalWidth() {
      return window.innerWidth;
    }
  },
  mounted() {
    this.loadProjects();
  },
  methods: {
    /**
     * Load the projects
     * @returns {Promise<boolean>}
     */
    loadProjects() {
      this.loading = true;
      let params = {
        orderBy: 'created_at',
        sortedBy: 'desc',
        limit: 100,
        public: true
      };
      return this.$store.dispatch('projects/load', params)
          .finally(() => this.loading = false);
    },

    /**
     * Create name icon base on name skill
     * @param skill
     * @returns {string}
     */
    getIconName(skill)
    {
      let iconName = skill.split('-')[0];
      return `mdi-${iconName} mdi-language-${iconName}`;
    }
  },
  created() {
    const readyHandler = () => {
      if (document.readyState === 'complete') {
        // this.loading = false;
        document.removeEventListener('readystatechange', readyHandler);
      }
    };
    document.addEventListener('readystatechange', readyHandler);
    readyHandler();
  },
}
</script>